import React, { useState } from "react";

const FloatingBackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", toggleVisibility);

  return (
    <>
      {isVisible && (
        <button
          id="back-to-top"
          className="floating-button"
          onClick={scrollToTop}
        >
          <i class="fas fa-arrow-up"></i>
        </button>
      )}
    </>
  );
};

export default FloatingBackToTopButton;
