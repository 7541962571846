import React from "react";
import Dighir from "./images/dighir_chap.jpg";
import Cafe from "./images/cafe_rain_drop.jpg";
import Projuktibid from "./images/projuktibid.jpg";
import Dhaba from "./images/dhaba_city.jpg";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-area mt-4">
            <div className="text-center">
              <h2 className="white">
                HAPPY <span className="blue">CLIENTS</span>
              </h2>
            </div>

            <div className="flex item-center justufy-center mt-2 p-4">
              <img src={Dighir} width="100vh" alt="client-logo" />
              <img src={Cafe} width="100vh" alt="client-logo" />
              <img src={Projuktibid} width="100vh" alt="client-logo" />
              <img src={Dhaba} width="100vh" alt="client-logo" />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
