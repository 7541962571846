import { useState, useEffect } from "react";

function Counter({ endNumber }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((counter) => {
        if (counter >= endNumber) {
          clearInterval(intervalId);
          return counter;
        } else {
          return counter + 1;
        }
      });
    }, 30);

    return () => clearInterval(intervalId);
  }, [endNumber]);

  return <span>{counter}</span>;
}

export default Counter;
