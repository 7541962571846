import React from "react";
import Rafi from "./images/rafi_bhuiyan.jpg";
import Shawon from "./images/shawon.jpg";
import Muntasir from "./images/muntasir.jpeg";
function Team() {
  return (
    <>
      <section className="team mt-4" id="team">
        <div className="container">
          <div className="about-area">
            <div className="text-center mt-4">
              <h2>MEET OUR</h2>
              <h2 className="blue">CREATIVE TEAM</h2>
              <p className="title">
              OUR DYNAMIC TEAM OF TECH AND MARKETING WIZARDS
              </p>
            </div>

            <div className="row mt-2">
              <div className="col">
                <div className="card">
                  <img src={Rafi} alt="team member" width="100%" height="100%" />

                  <div className="card-footer flex justufy-between item-center ">
                    <div className="text-start">
                      <h6 className="white">RAFI BHUIYAN</h6>
                      <p className="white">FOUNDER AND CEO</p>
                    </div>
                    <div>
                      <i className="white m-2 fa-brands fa-twitter hover"></i>
                      <i className="white m-2 fa-brands hover fa-instagram"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card">
                  <img src={Muntasir} alt="team member" width="100%" />

                  <div className="card-footer flex justufy-between item-center ">
                    <div className="text-start">
                      <h6 className="white">MUNTASIR ARAFAT</h6>
                      <p className="white">SOFTWARE DEVELOPER</p>
                    </div>
                    <div>
                      <i className="white m-2 hover fa-brands fa-twitter"></i>
                      <i className="white hover m-2 fa-brands fa-instagram"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card">
                  <img src={Shawon} alt="team member" width="100%" />

                  <div className="card-footer flex justufy-between item-center ">
                    <div className="text-start">
                      <h6 className="white">SHAWON KHAN</h6>
                      <p className="white">GRAPHICS DESIGNER</p>
                    </div>
                    <div>
                      <i className="white m-2 fa-brands hover fa-twitter"></i>
                      <i className="white m-2 fa-brands hover fa-instagram"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
